module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"blubits.me","short_name":"blubits.me","start_url":"/","background_color":"#f3f4f6","theme_color":"#f3f4f6","display":"standalone","icon":"src/images/favicon-ty.png","icons":[{"src":"src/images/favicon-ty.png","sizes":"32x32","type":"image/png"},{"src":"src/images/favicon-sm.png","sizes":"64x64","type":"image/png"},{"src":"src/images/favicon-md.png","sizes":"192x192","type":"image/png"},{"src":"src/images/favicon-lg.png","sizes":"512x512","type":"image/png"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"3786c13054a5b95305949f6d20736679"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
